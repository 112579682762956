<template>
  <div class="container">
    <div>
      <img class="rise-logo" src="@/assets/img/rise-logo.svg" alt="" srcset="">
    </div>
    <div class="p-2" style="width: 100%">
      <div class="text-left">
        <h1 class="h4 text-left text-gray-900 mb-2">Activação de conta</h1>
        <p class="mb-4">Aguarde até que sua conta seja activada.</p>
      </div>
      <form class="user" @submit.prevent.stop="() => {}">
        <div class="form-group loader-wrapper">
          <div class="loader" v-if="isLoading">
            <div class="face">
              <div class="circle"></div>
            </div>
            <div class="face">
              <div class="circle"></div>
            </div>
          </div>
          <div v-else>
            <div class="text-center">
              <i
                class="fa fa-4x mb-4"
                :class="{'fa-check-circle text-success': response.success, 'fa-ban text-danger': !response.success}"
              ></i>
              <p :class="{'text-sucess': response.success, 'text-danger': !response.success}">{{ response.message }}</p>
              <router-link class="small" :to="{ name: 'Login' }">
                <i class="fa fa-home"></i> &nbsp; Regressar a área de login!
              </router-link>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'Ativaction',
  created () {
    document.title = 'Rise | Ativação de conta'
  },
  data () {
    return {
      isLoading: true,
      response: {
        success: false,
        message: ''
      }
    }
  },
  methods: {
    ...mapActions('active_account', ['activation'])
  },
  mounted () {
    const { codigo, tipo } = this.$route.query
    if (!codigo) return
    this.activation(codigo)
      .then(data => {
        this.response.success = true
        this.response.message = data.message
        setTimeout(() => {
          this.$router.push({ name: tipo === 'profissional' ? 'Login' : 'LoginParceiro' })
        }, 2000)
      })
      .catch((err) => {
        this.response.success = false
        if (err.response) {
          this.response.message = err.response.data.message
        } else {
          this.response.message = err.message
        }
      })
      .finally(() => {
        setTimeout(() => {
          this.isLoading = false
        }, 3000)
      })
  }
}
</script>
<style scoped>
a {
  color: #FDB00B;
  font-family: "Muli", sans-serif !important;
  margin-left: 20px;
  font-weight: 500;
  font-size: 16px;
  margin-top: 12px;
}
.container{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.rise-logo{
  width: 150px;
  margin-bottom: 20px;
}
.loader-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 20vh;
}

.loader {
  width: 10em;
  height: 10em;
  font-size: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader .face {
  position: absolute;
  border-radius: 50%;
  border-style: solid;
  animation: animate 3s linear infinite;
}

.loader .face:nth-child(1) {
  width: 100%;
  height: 100%;
  color: #FDB00B;
  border-color: currentColor transparent transparent currentColor;
  border-width: 0.2em 0.2em 0em 0em;
  --deg: -45deg;
  animation-direction: normal;
}

.loader .face:nth-child(2) {
  width: 70%;
  height: 70%;
  color: linear-gradient(to bottom, #222, #000);
  border-color: currentColor currentColor transparent transparent;
  border-width: 0.2em 0em 0em 0.2em;
  --deg: -135deg;
  animation-direction: reverse;
}

.loader .face .circle {
  position: absolute;
  width: 50%;
  height: 0.1em;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: rotate(var(--deg));
  transform-origin: left;
}

.loader .face .circle::before {
  position: absolute;
  top: -0.5em;
  right: -0.5em;
  content: "";
  width: 1em;
  height: 1em;
  background-color: currentColor;
  border-radius: 50%;
  box-shadow: 0 0 2em, 0 0 4em, 0 0 6em, 0 0 8em, 0 0 10em,
    0 0 0 0.5em rgba(255, 255, 0, 0.1);
}

@keyframes animate {
  to {
    transform: rotate(1turn);
  }
}
</style>
